body { 

  margin: 0; padding: 0; 
	background: #000;
	color: #fff;
	font-family: 'Cabin', sans-serif;

 }

 a {

 	color: #fff;

 }

 .hidden {

  display: none !important;

 }

#header {
		background-image: url('/img/background.png'),

    linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(12,6,50,1) 100%);

		background-position: left bottom;
		background-size: contain; 
		background-repeat: no-repeat;
		max-height: 400px;
		color: #fff !important;
		border-radius: 0;
		text-align: left;
		transform: skewY(-12deg);
    position: relative;

}

#header-inner {
transform: skewY(12deg);
}

.header-virus {
  mix-blend-mode: color-dodge;
  position: fixed;
  width: 300px;
  height: 300px;
  opacity: 0.15;
  background-image: url('/img/virus.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: fadein_two 5s, rotation_cw 180s infinite linear;
  left:  30%;

}

@keyframes rotation_cw {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation_ccw {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

#header-bg {
   white-space: nowrap;
  overflow: hidden;
  mix-blend-mode: lighten;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url('/img/overview.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;  
  -webkit-animation: fadein_one 5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein_one 5s; /* Firefox < 16 */
        -ms-animation: fadein_one 5s; /* Internet Explorer */
         -o-animation: fadein_one 5s; /* Opera < 12.1 */
            animation: fadein_one 5s;

}

@keyframes fadein_one {
    from { opacity: 0; }
    to   { opacity: 0.6; }
}

/* Firefox < 16 */
@-moz-keyframes fadein_one {
    from { opacity: 0; }
    to   { opacity: 0.6; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein_one {
    from { opacity: 0; }
    to   { opacity: 0.6; }
}

/* Internet Explorer */
@-ms-keyframes fadein_one {
    from { opacity: 0; }
    to   { opacity: 0.6; }
}

/* Opera < 12.1 */
@-o-keyframes fadein_one {
    from { opacity: 0; }
    to   { opacity: 0.15; }
}


@keyframes fadein_two {
    from { opacity: 0; }
    to   { opacity: 0.15; }
}

footer {

  text-shadow: 3px 2px 60px rgba(41, 177, 75, 0.8); 
  font-size: 0.8em; 
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left; 
  /*background: rgba(43,46,111,0.5);
   padding: 20px; 
   */

}

#map:before {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 10;
    pointer-events: none;
 	/*
 	-webkit-box-shadow: inset 10px 10px 9px 25px rgba(0,0,0,1);
    -moz-box-shadow: inset 10px 10px 9px 25px rgba(0,0,0,1);
    box-shadow: inset 10px 10px 9px 25px rgba(0,0,0,1);
    */
    
}

.navbar-brand {

	font-weight: 700;

}
a.navbar-brand:hover {

  color: #fff  !important;

}
.navbar-brand > img {

	height:50px;

}

.cover-inner p, .cover-inner h1 {

	/*text-shadow: 3px 2px 60px rgba(41, 177, 75, 0.8);*/

}

.stats-num {

	font-size: 2em; 
	float: right;

}

.stats-header {

	float:left;

}
.stats-subtext {

	font-size: 80%;
    font-weight: 400;
    display: inline-block;

}

#stats-phe {

	background: #C94450; 
	color: #fff; 
	font-weight: 700;

}

.arrow {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 50px;

}

.arrow-first {
  -webkit-animation: arrow-movement 2s ease-in-out infinite;
          animation: arrow-movement 2s ease-in-out infinite;
}

.arrow-second {
  -webkit-animation: arrow-movement 2s 2s ease-in-out infinite;
          animation: arrow-movement 2s 2s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
  background: #fff;
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
}

.arrow:before {
  -webkit-transform: rotate(45deg) translateX(-23%);
          transform: rotate(45deg) translateX(-23%);
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.arrow:after {
  -webkit-transform: rotate(-45deg) translateX(23%);
          transform: rotate(-45deg) translateX(23%);
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

@-webkit-keyframes arrow-movement {
  0% {
    opacity: 0;
    bottom: 75px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes arrow-movement {
  0% {
    opacity: 0;
    bottom: 75px;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#crowdsourced {

  opacity: 0.5;

}

.smallheading {

  padding-top:20px;
  padding-bottom:20px;
  font-weight: 700;

}

.smallheading > img {

 height:30px

}
.features {
  position: relative;
  bottom: 0;
  text-align: center;
  padding: 20px;

}

.features > img {

  width: 50px;

}

.symptoms-flu {

  background: #C94450; 
  color: #fff; 
  font-weight: 700;

}

.symptoms-any {

  background: #c97344;
   color: #fff; 
  font-weight: 700;

}

.symptoms-none {

  background: #1a2536;
   color: #fff; 
  font-weight: 700;

}

.mapboxgl-popup-content {

  background-image: url('/img/overview.png'), 
  linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(12,6,50,0.5) 100%);

  background-position: bottom;
   font-weight: 700;
   font-family: 'Cabin', sans-serif;
   width: 150px;
   text-align: center;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: rgba(0,0,0,0.85);
    }
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: rgba(0,0,0,0.85);
    }
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: rgba(0,0,0,0.85);
    }
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: rgba(0,0,0,0.85);
    }

.mapboxgl-popup-close-button {

  display: none;
}

#scroll {

  text-align: center;

}

#symptoms img {

  width: 30px; 
  margin-right: 10px;

}

.symptoms-good {

  background: rgba(42, 179, 75, 0.95) !important;
  border: none;
  margin-bottom: 20px;
  margin-top: 20px;

}

.symptoms-bad {

  background: rgba(201, 68, 80, 0.95) !important;
  border: none;

}

.modal-header {

  border-bottom: none;

}

  @media only screen and (max-width: 600px) {

    #overview {

      background: url('/img/overview.png')  rgba(12,6,50,0.9) 100% !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
    }

    }

#overview {

  text-shadow: 3px 2px 60px rgba(41, 177, 75, 0.8); 
  padding: 20px; 
  border-radius: 4px;
  background-image: url('/img/overview.png'), 
  linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(12,6,50,0.5) 100%);
  background-size: cover;
  background-position: center;
 /* animation: animatedBackground 60s linear infinite;*/
}

#overview p {

  margin: 0;

}

.modal-content {

  background-image: url('/img/overview.png'),
  linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(12,6,50,1) 100%);
  text-shadow: 3px 2px 60px rgba(41, 177, 75, 0.8); 

-webkit-box-shadow: -2px 2px 91px 30px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 2px 91px 30px rgba(0,0,0,0.75);
box-shadow: -2px 2px 91px 30px rgba(0,0,0,0.75);
  
  background-size: cover;
}

.modal-footer {

  line-height: normal;
}

.modal-content .btn-primary {

  background: #000;
  border: 1px solid #fff;

}

.modal-title img {

  height: 40px;

}

label.form-label {
    font-weight: 600;
    display: block;
}

.form-control {
  width: fit-content;

}

.navbar {

  padding-left: 10px;

}

.modal-backdrop.show {

    background-image: url('/img/overview.png'),
  linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(12,6,50,1) 100%);
  opacity: 1;

  background-size: cover;
}

.wrap {
  display: inline-flex;
  height: 300px;

}

.inner {

  margin: auto 5px;   

}

#crowdsourced {

height: 300px;

}

@keyframes animatedBackground {
  from { background-position: 0 0; }
  to { background-position: 50% 0; }
}

button:focus {

  border: none !important;

}




/* The heart of the matter */
.btn-matrix > .btn:nth-child(3n+4) {
  clear: left;
  margin-left: 0;
}
.btn-matrix > .btn:nth-child(n+4) {
  margin-top: -1px;
}
.btn-matrix > .btn:first-child {
  border-bottom-left-radius: 0;
}
.btn-matrix > .btn:nth-child(3) {
  border-top-right-radius: 4px !important;
}
.btn-matrix > .btn:nth-last-child(3) {
  border-bottom-left-radius: 4px !important;
}
.btn-matrix > .btn:last-child {
  border-top-right-radius: 0;
}

/* Decorations */
.btn-matrix {
  margin: 20px;
}




.MuiToggleButton-root:focus {

  border: 1px solid #fff !important;
  outline: none !important;

}

.MuiToggleButton-root.Mui-selected {

  opacity: 1;
  background-image: url('/img/virus.png'),  linear-gradient(180deg, rgba(201, 68, 80, 0.7) 0%, rgba(201, 68, 80,  0.7) 100%) !important;
  background-blend-mode: hard-light;
  border: 1px solid #fff;
}

.MuiToggleButton-label {
   
  font-weight: 600 !important;
  line-height: initial;
  color: #fff !important;
  text-transform: none;
    padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;

}

.MuiToggleButton-root.Mui-selected > .MuiToggleButton-label {

  background: rgba(0,0,0,0.9);
  padding-top: 15px;
  padding-bottom: 15px;
  width: 98px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;

}

.MuiToggleButton-root {

    background: #000 !important;
  opacity: 0.4;
  font-family: inherit;
  height: 100px !important;
  width: 100px !important;
  padding: 0px !important;

  display: inline-block !important;
  margin-right: 16px !important;
  margin-bottom: 16px !important;
 

}

  @media only screen and (max-width: 600px) {

  .MuiToggleButton-root {

     margin-right: 18px !important;
     margin-bottom: 18px !important;

     width: 90px !important;
     height: 90px !important;

  }

  .MuiToggleButton-root.Mui-selected > .MuiToggleButton-label {

    width: 88px !important;
      padding-top: 15px;
  padding-bottom: 15px;

  }

}

.MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected {

  border-left: 1px solid #fff !important;

}

button:focus {

  border: inherit;
  outline: inherit;

}

#postcode {

  text-transform: uppercase;

}

.btn-primary.focus, .btn-primary:focus, .btn.focus, .btn:focus {

  box-shadow: none !important;

}

.form-num {

  width: 65px;

}

.form-temperature {

  width: 100px;

}

hr {

  border-top: 1px solid #fff;

}

.input-group-text {


  background: transparent;
  color: #fff;
  font-weight: 700;

}

.form-gender {

  padding-right: 20px;

}

#postcode {

  width: 110px;
}

#symptoms svg {

  stroke: #fff;
  margin-left: 10px;

}

#phe-wrapper {

  margin-top: 60px;
  margin-bottom: 60px;

}

@media only screen and (max-width: 600px) {

  #phe-wrapper {

   margin-top: 30px;
    margin-bottom: 30px;

  }

}


a:hover {

  text-decoration: none;
  color: inherit;
  font-weight: 700 !important;

}

.postcode-sector {

  line-height: 15px;
  margin-top:10px;
  font-size: 0.8rem;

}

@media only screen and (max-width: 600px) {

  #social-tr {

    display: none !important;


  }

}

#social-tr {

  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 10px;

}

#social-tr .fb-share-button {

  margin-left: 10px;

}

#modal-footer {

  font-size: 0.8rem;

} 